import { LocaleHelper } from '@bryntum/schedulerpro';
import FrLocale from '@bryntum/schedulerpro/locales/schedulerpro.locale.FrFr.js';

const craneOperatorDispatch = {
  craneOperator: 'Grutier',
  branch: 'Succursale',
  branchNameField: 'shortNameFr',
  groupSortField: 'name',
};

const craneDispatch = {
  crane: 'Grue',
  branch: 'Succursale',
  branchNameField: 'shortNameFr',
  editCrane: 'Editer grue',
  editRollingStock: 'Editer matériel roulant',
  equipmentStatus: 'Statut grue',
  preferredOperators: 'Grutiers attitrés',
};

const locale = LocaleHelper.mergeLocales(FrLocale, {
  localeName: 'fr',
  localeDesc: 'Français (Canada)',
  localeCode: 'fr-CA',
  craneOperatorDispatch,
  craneDispatch,
});

export default locale;
