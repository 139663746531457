const languages = {
  en: 'English',
  fr: 'French',
};

const app = {
  title: 'GUAY',
  version: 'Version',
};

const sideBar = {
  actions: 'Actions',
  consultation: 'Consultation',
  settings: 'Réglages',
};

const auth = {
  logout: 'Se déconnecter',
  redirectToConnectionPage: 'Vous serez redirigé vers la page de connection.',
  unauthorizedLogin: 'Connexion non autorisée',
  unauthorizedLoginMessage: "Vous n'avez pas accès à l'application avec cette adresse courriel",
};

const navBar = {
  userSettings: 'Paramètres',
  privacyPolicy: 'Politique de confidentialité',
};

const logoutModal = {
  title: 'Confirmation',
  confirmationText: 'Voulez-vous vraiment vous déconnecter ?',
  confirmButton: 'Déconnecter',
};

const insufficientPermissions = {
  title: 'Permissions insuffisantes',
};

const notifications = {
  departureDate: 'Date de départ',
  arrivalDate: "Date d'arrivée",
  readyDate: 'Date prêt',
  isSecondServing: 'Deuxième tablée',
  capacity: 'Capacité',
  equipmentType: "Type d'équipement",
  preventSubcontracting: 'Prévenir la sous-traitance',
  title: 'Notifications',
  newValue: 'Nouvelle valeur',
  oldValue: 'Ancienne valeur',
  client: 'Client',
  accessories: 'Accessoires',
  status: 'Statut',
  ot: "L'OT",
  continuity: 'La continuité',
  modified: 'a été modifié.',
  continuityModified: 'a été modifiée.',
  empty: 'Aucune notification.',
  durationInDays: 'Durée en jours',
  hoursPerDay: 'Heures par jour',
  address: 'Adresse',
  workType: 'Nature du travail',
  boomLength: 'Longueur de la flèche',
  jibLength: 'Longueur de la fléchette',
  offsetAngle: 'Offset',
  counterweight: 'Contrepoids',
  makeAndModels: 'Marque et modèle',
  radius: 'Rayon de travail',
  maxWeight: 'Poids de la charge',
  preventReplacement: 'Prévenir le remplacement',
  serviceCallStatus: {
    NONE: 'Aucun',
    DEFAULT: 'Défaut',
    InWriting: 'En écriture',
    Reserved: 'Réservé',
    Transferred: 'Transféré',
    Delayed: 'Délayé',
    Dispatched: 'Réparti',
    Canceled: 'Annulé',
  },
  days: 'jours',
  hours: 'heures',
  feet: 'pi',
  pounds: 'lb',
  of: 'du',
  from: 'du',
  to: 'au',
  true: 'Vrai',
  false: 'Faux',
  transportsLastDayContinuityChanged: {
    title_last: 'Le dernier jour de continuité {{clientName}} est passé du {{oldDate}} au {{newDate}}.',
    title_first: 'Le premier jour de continuité {{clientName}} est passé du {{oldDate}} au {{newDate}}.',
    content_last: 'Les transports ont été déplacés à la nouvelle date de fin de la continuité ({{newDate}}).',
    content_first: 'Les transports ont été déplacés à la nouvelle date de départ de la continuité ({{newDate}}).',
  },
  transportRemoved: {
    title: "L'appel de service de la continuité {{clientName}} a été modifié.",
    content_single: 'Un transport planifié de type {{codeSubcode}} - {{codeDescription}} du {{date}} a été retiré.',
    content_multiple: '{{quantityDeleted}} transports planifiés de type {{codeSubcode}} - {{codeDescription}} du {{date}} ont été retirés.',
  },
  impossibleOptimization: {
    title: 'Échec d’assignation automatisée pour {{clientName}} le {{date}}',
  },
  craneConfigurationUpdated: {
    title:
      "La configuration des grues a été modifiée dans l'appel de service {{clientName}} {{friendlyId}} du {{date}}. Les ordres de travail associés doivent être planifiés de nouveau.",
  },
};

export const layout = {
  app,
  auth,
  insufficientPermissions,
  languages,
  navBar,
  sideBar,
  logoutModal,
  notifications,
};
